exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

